export const identifyUser = (userId?: string) => {
  if (!window.heap) return;

  if (userId) {
    window.heap.identify(userId);
  }

  const userProperties = {
    partner_id: window.AppData.PartnerId,
    partner_category: window.AppData.PartnerCategory,
    partner_name: window.AppData.PartnerName,
  };

  Object.keys(userProperties).forEach((key) => {
    if (!userProperties[key]) {
      delete userProperties[key];
    }
  });

  window.heap.addUserProperties(userProperties);
};

export const trackEvent = (eventName: string) => {
  if (!window.heap) return;

  window.heap.track(eventName);
};
